/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import "./layout.css"

const Layout = ({ children }) => {
  return (
    <>
      <main className="text-center">{children}</main>
      <footer className="text-center text-gray-600 px-4 py-8">
        <small>Pradeep CE | Copyright &copy; {new Date().getFullYear()} | pradeepcep@gmail.com | <span title="Site design last updated at probably some insane hour on 05 Jul 2020">v2.0</span></small>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
